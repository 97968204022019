






































import IconAlertCircle from '@/components/UI/icons/IconAlertCircle.vue'
import { uiStore } from '@/store'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    IconAlertCircle,
  },
})
export default class DeniedError extends Vue {
  @Prop({ type: Boolean, default: false }) private readonly camDenied!: boolean
  @Prop({ type: Boolean, default: false }) private readonly micDenied!: boolean

  openUnblockMicModal () {
    uiStore.actions.showModal({ instance: 'UnblockMicModal' })
  }
}
